import { default as edituGEemxgmMTMeta } from "/app/main/pages/admin/asset-groups/edit.vue?macro=true";
import { default as indexRTNnP4EjfwMeta } from "/app/main/pages/admin/asset-groups/index.vue?macro=true";
import { default as newzbDrBy8vM8Meta } from "/app/main/pages/admin/asset-groups/new.vue?macro=true";
import { default as editSiIyC9LMGGMeta } from "/app/main/pages/admin/assets/edit.vue?macro=true";
import { default as indexH4Eeu4f4lUMeta } from "/app/main/pages/admin/assets/index.vue?macro=true";
import { default as newpL45MWEHohMeta } from "/app/main/pages/admin/assets/new.vue?macro=true";
import { default as editFTrWhmWLqfMeta } from "/app/main/pages/admin/attribute-groups/edit.vue?macro=true";
import { default as indexJMb1rdxdS4Meta } from "/app/main/pages/admin/attribute-groups/index.vue?macro=true";
import { default as newx8PEebO2WTMeta } from "/app/main/pages/admin/attribute-groups/new.vue?macro=true";
import { default as editUqeeEtZUVPMeta } from "/app/main/pages/admin/attributes/edit.vue?macro=true";
import { default as indexlklEu18RcmMeta } from "/app/main/pages/admin/attributes/index.vue?macro=true";
import { default as new9Fgv8ZtExiMeta } from "/app/main/pages/admin/attributes/new.vue?macro=true";
import { default as indexlpBEOCkYsIMeta } from "/app/main/pages/admin/bridges/index.vue?macro=true";
import { default as editCwxZSbYsf6Meta } from "/app/main/pages/admin/interfaces/edit.vue?macro=true";
import { default as index1NWBHq7bbKMeta } from "/app/main/pages/admin/interfaces/index.vue?macro=true";
import { default as new9iSTgWhqUIMeta } from "/app/main/pages/admin/interfaces/new.vue?macro=true";
import { default as _91id_93M6qL2LcIoTMeta } from "/app/main/pages/admin/menus/edit/[id].vue?macro=true";
import { default as index7g7m77oBNWMeta } from "/app/main/pages/admin/menus/index.vue?macro=true";
import { default as newht3wEOb9vFMeta } from "/app/main/pages/admin/menus/new.vue?macro=true";
import { default as editM2GswajWMLMeta } from "/app/main/pages/assessment/export/edit.vue?macro=true";
import { default as indexECLs1kCjrNMeta } from "/app/main/pages/assessment/export/index.vue?macro=true";
import { default as printi4EKsdPbEyMeta } from "/app/main/pages/assessment/export/print.vue?macro=true";
import { default as sap_45importz91yuL62R2Meta } from "/app/main/pages/assessment/sap-import.vue?macro=true";
import { default as viewsup3TnOmeEsMeta } from "/app/main/pages/assessment/views.vue?macro=true";
import { default as login8KzKOG5OYeMeta } from "/app/main/pages/auth/login.vue?macro=true";
import { default as logoutbFXmCqhPkvMeta } from "/app/main/pages/auth/logout.vue?macro=true";
import { default as indexLHUOdztsrSMeta } from "/app/main/pages/index.vue?macro=true";
import { default as emission_45productionhdwisJAmDZMeta } from "/app/main/pages/planning/emission-production.vue?macro=true";
import { default as energy_45consumptionhOwciCUXldMeta } from "/app/main/pages/planning/energy-consumption.vue?macro=true";
import { default as fuel_45consumptionUdivgs7J4WMeta } from "/app/main/pages/planning/fuel-consumption.vue?macro=true";
import { default as fuel_45supplyxM7qqlagg9Meta } from "/app/main/pages/planning/fuel-supply.vue?macro=true";
import { default as gas_45consumptionODG7wIBN4HMeta } from "/app/main/pages/planning/gas-consumption.vue?macro=true";
import { default as heat_45distributionEI0O96Dh5BMeta } from "/app/main/pages/planning/heat-distribution.vue?macro=true";
import { default as ispp_45importk8R1VaurQCMeta } from "/app/main/pages/planning/ispp-import.vue?macro=true";
import { default as variable_45expensesL1dz3wK8zuMeta } from "/app/main/pages/planning/variable-expenses.vue?macro=true";
import { default as vep_45usageQpHxfLtIvuMeta } from "/app/main/pages/planning/vep-usage.vue?macro=true";
import { default as contract_45registryqKIpGBZcxvMeta } from "/app/main/pages/preparation/contract-registry.vue?macro=true";
import { default as data_45for_45central5rJeuizI5hMeta } from "/app/main/pages/preparation/data-for-central.vue?macro=true";
import { default as indexJuA8tqo3AQMeta } from "/app/main/pages/preparation/index.vue?macro=true";
import { default as ispp_45exportm3RlUTkbstMeta } from "/app/main/pages/preparation/ispp-export.vue?macro=true";
import { default as plan_45heat_45supplyRQ38Ftgt2pMeta } from "/app/main/pages/preparation/plan-heat-supply.vue?macro=true";
import { default as plan_45outageskNyvB6VNInMeta } from "/app/main/pages/preparation/plan-outages.vue?macro=true";
import { default as plan_45parameters9vcLGDmwf3Meta } from "/app/main/pages/preparation/plan-parameters.vue?macro=true";
import { default as power_45modeljXBAKZkSAJMeta } from "/app/main/pages/preparation/power-model.vue?macro=true";
import { default as standards_45catalogueC5ixRYY2kvMeta } from "/app/main/pages/preparation/standards-catalogue.vue?macro=true";
import { default as tariff_45listGIpU8wOQNwMeta } from "/app/main/pages/preparation/tariff-list.vue?macro=true";
export default [
  {
    name: "admin-asset-groups-edit",
    path: "/admin/asset-groups/edit",
    meta: edituGEemxgmMTMeta || {},
    component: () => import("/app/main/pages/admin/asset-groups/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-asset-groups",
    path: "/admin/asset-groups",
    meta: indexRTNnP4EjfwMeta || {},
    component: () => import("/app/main/pages/admin/asset-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-asset-groups-new",
    path: "/admin/asset-groups/new",
    meta: newzbDrBy8vM8Meta || {},
    component: () => import("/app/main/pages/admin/asset-groups/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-edit",
    path: "/admin/assets/edit",
    meta: editSiIyC9LMGGMeta || {},
    component: () => import("/app/main/pages/admin/assets/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets",
    path: "/admin/assets",
    meta: indexH4Eeu4f4lUMeta || {},
    component: () => import("/app/main/pages/admin/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-assets-new",
    path: "/admin/assets/new",
    meta: newpL45MWEHohMeta || {},
    component: () => import("/app/main/pages/admin/assets/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-attribute-groups-edit",
    path: "/admin/attribute-groups/edit",
    meta: editFTrWhmWLqfMeta || {},
    component: () => import("/app/main/pages/admin/attribute-groups/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-attribute-groups",
    path: "/admin/attribute-groups",
    meta: indexJMb1rdxdS4Meta || {},
    component: () => import("/app/main/pages/admin/attribute-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-attribute-groups-new",
    path: "/admin/attribute-groups/new",
    meta: newx8PEebO2WTMeta || {},
    component: () => import("/app/main/pages/admin/attribute-groups/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-attributes-edit",
    path: "/admin/attributes/edit",
    meta: editUqeeEtZUVPMeta || {},
    component: () => import("/app/main/pages/admin/attributes/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-attributes",
    path: "/admin/attributes",
    meta: indexlklEu18RcmMeta || {},
    component: () => import("/app/main/pages/admin/attributes/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-attributes-new",
    path: "/admin/attributes/new",
    meta: new9Fgv8ZtExiMeta || {},
    component: () => import("/app/main/pages/admin/attributes/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-bridges",
    path: "/admin/bridges",
    component: () => import("/app/main/pages/admin/bridges/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-interfaces-edit",
    path: "/admin/interfaces/edit",
    meta: editCwxZSbYsf6Meta || {},
    component: () => import("/app/main/pages/admin/interfaces/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-interfaces",
    path: "/admin/interfaces",
    meta: index1NWBHq7bbKMeta || {},
    component: () => import("/app/main/pages/admin/interfaces/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-interfaces-new",
    path: "/admin/interfaces/new",
    meta: new9iSTgWhqUIMeta || {},
    component: () => import("/app/main/pages/admin/interfaces/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-menus-edit-id",
    path: "/admin/menus/edit/:id()",
    meta: _91id_93M6qL2LcIoTMeta || {},
    component: () => import("/app/main/pages/admin/menus/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-menus",
    path: "/admin/menus",
    meta: index7g7m77oBNWMeta || {},
    component: () => import("/app/main/pages/admin/menus/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-menus-new",
    path: "/admin/menus/new",
    meta: newht3wEOb9vFMeta || {},
    component: () => import("/app/main/pages/admin/menus/new.vue").then(m => m.default || m)
  },
  {
    name: "assessment-export-edit",
    path: "/assessment/export/edit",
    meta: editM2GswajWMLMeta || {},
    component: () => import("/app/main/pages/assessment/export/edit.vue").then(m => m.default || m)
  },
  {
    name: "assessment-export",
    path: "/assessment/export",
    meta: indexECLs1kCjrNMeta || {},
    component: () => import("/app/main/pages/assessment/export/index.vue").then(m => m.default || m)
  },
  {
    name: "assessment-export-print",
    path: "/assessment/export/print",
    meta: printi4EKsdPbEyMeta || {},
    component: () => import("/app/main/pages/assessment/export/print.vue").then(m => m.default || m)
  },
  {
    name: "assessment-sap-import",
    path: "/assessment/sap-import",
    meta: sap_45importz91yuL62R2Meta || {},
    component: () => import("/app/main/pages/assessment/sap-import.vue").then(m => m.default || m)
  },
  {
    name: "assessment-views",
    path: "/assessment/views",
    meta: viewsup3TnOmeEsMeta || {},
    component: () => import("/app/main/pages/assessment/views.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login8KzKOG5OYeMeta || {},
    component: () => import("/app/main/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutbFXmCqhPkvMeta || {},
    component: () => import("/app/main/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexLHUOdztsrSMeta || {},
    component: () => import("/app/main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "planning-emission-production",
    path: "/planning/emission-production",
    meta: emission_45productionhdwisJAmDZMeta || {},
    component: () => import("/app/main/pages/planning/emission-production.vue").then(m => m.default || m)
  },
  {
    name: "planning-energy-consumption",
    path: "/planning/energy-consumption",
    meta: energy_45consumptionhOwciCUXldMeta || {},
    component: () => import("/app/main/pages/planning/energy-consumption.vue").then(m => m.default || m)
  },
  {
    name: "planning-fuel-consumption",
    path: "/planning/fuel-consumption",
    meta: fuel_45consumptionUdivgs7J4WMeta || {},
    component: () => import("/app/main/pages/planning/fuel-consumption.vue").then(m => m.default || m)
  },
  {
    name: "planning-fuel-supply",
    path: "/planning/fuel-supply",
    meta: fuel_45supplyxM7qqlagg9Meta || {},
    component: () => import("/app/main/pages/planning/fuel-supply.vue").then(m => m.default || m)
  },
  {
    name: "planning-gas-consumption",
    path: "/planning/gas-consumption",
    meta: gas_45consumptionODG7wIBN4HMeta || {},
    component: () => import("/app/main/pages/planning/gas-consumption.vue").then(m => m.default || m)
  },
  {
    name: "planning-heat-distribution",
    path: "/planning/heat-distribution",
    meta: heat_45distributionEI0O96Dh5BMeta || {},
    component: () => import("/app/main/pages/planning/heat-distribution.vue").then(m => m.default || m)
  },
  {
    name: "planning-ispp-import",
    path: "/planning/ispp-import",
    meta: ispp_45importk8R1VaurQCMeta || {},
    component: () => import("/app/main/pages/planning/ispp-import.vue").then(m => m.default || m)
  },
  {
    name: "planning-variable-expenses",
    path: "/planning/variable-expenses",
    meta: variable_45expensesL1dz3wK8zuMeta || {},
    component: () => import("/app/main/pages/planning/variable-expenses.vue").then(m => m.default || m)
  },
  {
    name: "planning-vep-usage",
    path: "/planning/vep-usage",
    meta: vep_45usageQpHxfLtIvuMeta || {},
    component: () => import("/app/main/pages/planning/vep-usage.vue").then(m => m.default || m)
  },
  {
    name: "preparation-contract-registry",
    path: "/preparation/contract-registry",
    meta: contract_45registryqKIpGBZcxvMeta || {},
    component: () => import("/app/main/pages/preparation/contract-registry.vue").then(m => m.default || m)
  },
  {
    name: "preparation-data-for-central",
    path: "/preparation/data-for-central",
    meta: data_45for_45central5rJeuizI5hMeta || {},
    component: () => import("/app/main/pages/preparation/data-for-central.vue").then(m => m.default || m)
  },
  {
    name: "preparation",
    path: "/preparation",
    component: () => import("/app/main/pages/preparation/index.vue").then(m => m.default || m)
  },
  {
    name: "preparation-ispp-export",
    path: "/preparation/ispp-export",
    meta: ispp_45exportm3RlUTkbstMeta || {},
    component: () => import("/app/main/pages/preparation/ispp-export.vue").then(m => m.default || m)
  },
  {
    name: "preparation-plan-heat-supply",
    path: "/preparation/plan-heat-supply",
    meta: plan_45heat_45supplyRQ38Ftgt2pMeta || {},
    component: () => import("/app/main/pages/preparation/plan-heat-supply.vue").then(m => m.default || m)
  },
  {
    name: "preparation-plan-outages",
    path: "/preparation/plan-outages",
    meta: plan_45outageskNyvB6VNInMeta || {},
    component: () => import("/app/main/pages/preparation/plan-outages.vue").then(m => m.default || m)
  },
  {
    name: "preparation-plan-parameters",
    path: "/preparation/plan-parameters",
    meta: plan_45parameters9vcLGDmwf3Meta || {},
    component: () => import("/app/main/pages/preparation/plan-parameters.vue").then(m => m.default || m)
  },
  {
    name: "preparation-power-model",
    path: "/preparation/power-model",
    meta: power_45modeljXBAKZkSAJMeta || {},
    component: () => import("/app/main/pages/preparation/power-model.vue").then(m => m.default || m)
  },
  {
    name: "preparation-standards-catalogue",
    path: "/preparation/standards-catalogue",
    meta: standards_45catalogueC5ixRYY2kvMeta || {},
    component: () => import("/app/main/pages/preparation/standards-catalogue.vue").then(m => m.default || m)
  },
  {
    name: "preparation-tariff-list",
    path: "/preparation/tariff-list",
    meta: tariff_45listGIpU8wOQNwMeta || {},
    component: () => import("/app/main/pages/preparation/tariff-list.vue").then(m => m.default || m)
  }
]