export const useApi = async (url: string, method = 'GET', options = {}) => {
  const config = useRuntimeConfig()
  const authHeader = localStorage.getItem('authHeader')
  const selectedIsvApiUrl = localStorage.getItem('selectedIsvApiUrl')
  const baseUrl = selectedIsvApiUrl || config.public.apiUrl

  const response = await fetch(`${baseUrl}${url}`, {
    ...options,
    method,
    body: options.data ? JSON.stringify(options.data) : undefined,
    headers: {
      Authorization: authHeader,
      'Content-Type': 'application/json',
      ...(options?.headers || {})
    }
  })

  return await response.json()
}
