import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const authStore = useAuthStore()

  const isvCachedState = localStorage.getItem('isvPiniaState')

  if (isvCachedState && JSON.parse(isvCachedState).auth) {
    // FIXME user is not saved during login
    return
  }
  // if (location.href.startsWith('http://localhost:3000')) {
  //   return
  // }

  if (!['/auth/login/', '/auth/login', '/auth/logout/', '/auth/logout'].includes(to.path)) {
    if (authStore.user?.name) {
      return
    }

    if (!authStore.user?.name) {
      try {
        await authStore.getUserDetail()
      } catch (e) {
        return navigateTo('/auth/login')
      }
    }

    if (!authStore.user?.name) {
      return navigateTo('/auth/login')
    }
  }
})
