interface State {
  // user?: User
  header?: string
  role: 'admin' | 'economist'
  user?: { name: string }
}

// interface User {
//   userId: string
//   distinguishedName: string
//   displayName: string
//   firstName: string
//   lastName: string
//   mail: string
//   name: string
//   company?: string
//   department?: string
//   employeeNumber?: string
//   locality?: string
//   manager?: string
//   mobile?: string
//   phone1?: string
//   phone2?: string
//   place?: string
//   profession?: string
// }

// const TEMP_AUTH_API_URL = 'https://ceztrading-apps-test.apps.ocd.cc.corp'

export const useAuthStore = defineStore('auth', {
  state(): State {
    return {
      header: undefined,
      role: 'admin',
      user: undefined
    }
  },

  actions: {
    async getUserDetail() {
      // const headers: Record<string, string> = {
      //   'Content-Type': 'application/json'
      // }
      //
      // const token = localStorage.getItem('LDAP_TOKEN')
      //
      // if (token !== null) {
      //   headers['Authorization'] = token
      // }
      //
      // const userResponse = await fetch(`${TEMP_AUTH_API_URL}/authserviceldap/v1.0/userDetail`, {
      //   method: 'POST',
      //   body: JSON.stringify({}),
      //   headers
      // })
      //
      // const userData = await userResponse.json()
      //
      // if (!userData.name) {
      //   throw new Error('Failed to fetch user data. Please login again.')
      // }
      //
      // this.user = userData
    },

    async login(username: string, password: string) {
      // const loginResponse = await fetch(`${TEMP_AUTH_API_URL}/authserviceldap/v1.0/login`, {
      //   method: 'POST',
      //   body: JSON.stringify({ username, password }),
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // })
      // const tokenData = await loginResponse.json()
      //
      // if (!tokenData.accessToken) {
      //   throw new Error('Invalid credentials')
      // }
      //
      // localStorage.setItem('LDAP_TOKEN', `${tokenData.tokenType} ${tokenData.accessToken}`)
      const header = `Basic ${btoa(`${username}:${password}`)}`
      localStorage.setItem('authHeader', header)

      const loginResponse = await useApi('/auth/check')

      if (!loginResponse.preferred_username) {
        throw new Error('Přihlášení se nezdařilo')
      }

      this.user = { name: loginResponse.preferred_username }
      this.header = header
    }
  }
})
