import revive_payload_client_Frd8gASYWa from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/main/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_2MWhypiRxk from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_iRAWUIsBt4 from "/app/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/app/main/.nuxt/primevue-plugin.mjs";
import plugin_client_l97pGnUeXN from "/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import directives_yBPzJQtdia from "/app/layers/tairo/plugins/directives.ts";
import plotly_IFi26dH3Wn from "/app/main/plugins/plotly.ts";
import moment_NWWiRYbNqj from "/app/main/plugins/moment.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_client_2MWhypiRxk,
  plugin_iRAWUIsBt4,
  primevue_plugin_egKpok8Auk,
  plugin_client_l97pGnUeXN,
  directives_yBPzJQtdia,
  plotly_IFi26dH3Wn,
  moment_NWWiRYbNqj
]