import moment from 'moment'

// TODO rewrite to composable
export default defineNuxtPlugin(() => {
  return {
    provide: {
      moment
    }
  }
})
